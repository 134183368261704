<template>
  <div class="hello">    
    <img width=100% src="../assets/img/logo.png" />
    <h2>youtube</h2>
    <div class="iframe-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/m1rKzx8bva4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <h2>soundcloud</h2>
    <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1366637776&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/djskip_official" title="DJ SKIP" target="_blank" style="color: #cccccc; text-decoration: none;">DJ SKIP</a> · <a href="https://soundcloud.com/djskip_official/sets/a-mix-to-party" title="A mix to party" target="_blank" style="color: #cccccc; text-decoration: none;">A mix to party</a></div>
    <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1366636174&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/djskip_official" title="DJ SKIP" target="_blank" style="color: #cccccc; text-decoration: none;">DJ SKIP</a> · <a href="https://soundcloud.com/djskip_official/sets/chill-mixes" title="A mix to chill" target="_blank" style="color: #cccccc; text-decoration: none;">A mix to chill</a></div>
    <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1366271470&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/djskip_official" title="DJ SKIP" target="_blank" style="color: #cccccc; text-decoration: none;">DJ SKIP</a> · <a href="https://soundcloud.com/djskip_official/sets/radio" title="Radio" target="_blank" style="color: #cccccc; text-decoration: none;">Radio</a></div>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  margin: 40px 0 0;
}
.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
}
.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
